enum Actions {
  // action types
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LOAD_LOGIN_USER = "loadLoginUser",
  SAVE_TERMS = "saveTerms",
  LOAD_PERMISSION = "loadPermission",
  // documents
  LOAD_RECENT_BOOKS = "loadRecentBooks",
  LOAD_RECENT_ANNUALS = "loadRecentAnnuals",
  LOAD_RECENT_TABLES = "loadRecentTables",
  LOAD_RECENT_PUBLICATIONS = "loadRecentPublications",
  LOAD_DOCUMENT_SEARCH = "loadDocumentSearch",
  SEARCH_DOCUMENTS = "searchDocuments",
  DOWNLOAD_DOC = "downloadDoc",
  DOWNLOAD_SHARED_DOC = "downloadSharedDoc",
  DOWNLOAD_DOC_EF = "downloadDocEnergyFacts",
  LOAD_DOCUMENT = "loadDocument",
  CREATE_DOCUMENT = "createDocument",
  UPDATE_DOCUMENT = "updateDocument",
  DELETE_DOCUMENT = "deleteDocument",
  SHARE_DOCUMENT = "shareDocument",
  ADD_DOCUMENT_SECTORS = "addDocumentSectors",
  DELETE_DOCUMENT_SECTORS = "deleteDocumentSectors",
  LOAD_MINIMAL_DOCUMENTS = "loadMinimalDocuments",
  // users
  LOG_USER_ACTIVITY = "logUserActivity",
  SEARCH_USERS = "searchUsers",
  LOAD_USER = "loadUser",
  LOAD_USERS = "loadUsers",
  CREATE_USER = "createUser",
  UPDATE_USER = "updateUser",
  DELETE_USER = "deleteUser",
  LOAD_ALL_ROLES = "loadAllRoles",
  LOAD_USER_ROLES = "loadUserRoles",
  CHANGE_PASSWORD = "changePassword",
  RESET_PASSWORD = "resetPassword",
  VALIDATE_KEY = "validateKey",
  // user activity
  SEARCH_USER_ACTIVITY = "searchUserActivity",
  DOWNLOAD_USER_ACTIVITY = "downloadUserActivity",
  LOAD_USER_ACTIVITIY_SOURCES = "loadUserActivitySource",
  // companies
  SEARCH_COMPANIES = "searchCompanies",
  LOAD_COMPANY = "loadCompany",
  CREATE_COMPANY = "createCompany",
  UPDATE_COMPANY = "updateCompany",
  DELETE_COMPANY = "deleteCompany",
  // sectors
  SEARCH_SECTORS = "searchSectors",
  LOAD_SECTOR = "loadSector",
  CREATE_SECTOR = "createSector",
  UPDATE_SECTOR = "updateSector",
  DELETE_SECTOR = "deleteSector",
  // publication types
  SEARCH_PUBLICATION_TYPES = "searchPublicationTypes",
  LOAD_PUBLICATION_TYPE = "loadPublicationType",
  CREATE_PUBLICATION_TYPE = "createPublicationType",
  UPDATE_PUBLICATION_TYPE = "updatePublicationType",
  DELETE_PUBLICATION_TYPE = "deletePublicationType",
  // roles
  SEARCH_ROLES = "searchRoles",
  LOAD_ROLE = "loadRole",
  CREATE_ROLE = "createRole",
  UPDATE_ROLE = "updateRole",
  DELETE_ROLE = "deleteRole",
  LOAD_ALL_PERMISSIONS = "loadAllPermissions",
  LOAD_ROLE_PERMISSIONS = "loadRolePermissions",
  // email templates
  SEARCH_EMAIL_TEMPLATES = "searchEmailTemplates",
  LOAD_EMAIL_TEMPLATE = "loadEmailTemplate",
  CREATE_EMAIL_TEMPLATE = "createEmailTemplate",
  UPDATE_EMAIL_TEMPLATE = "updateEmailTemplate",
  DELETE_EMAIL_TEMPLATE = "deleteEmailTemplate",
  // messages
  CREATE_MESSAGE = "createMessage",
  // emails
  SEND_EMAIL = "sendEmail",
  SEARCH_EMAIL_DRAFT = "searchEmailDrafts",
  CREATE_EMAIL_DRAFT = "createEmailDraft",
  UPDATE_EMAIL_DRAFT = "updateEmailDraft",
  DELETE_EMAIL_DRAFT = "deleteEmailDraft",
  // holidays
  SEARCH_HOLIDAYS = "searchHolidays",
  LOAD_HOLIDAY = "loadHoliday",
  CREATE_HOLIDAY = "createHoliday",
  UPDATE_HOLIDAY = "updateHoliday",
  DELETE_HOLIDAY = "deleteHoliday",
  NEXT_BUSINESS_DAY = "appNextBusinessDay",

  //event downloads
  SEARCH_EVENT_DOWNLOADS = "searchEventDownloads",
  LOAD_EVENT_DOWNLOAD = "loadEventDownload",
  CREATE_EVENT_DOWNLOAD = "createEventDownload",
  DELETE_EVENT_DOWNLOAD = "deleteEventDownload",
  UPDATE_EVENT_DOWNLOAD = "updateEventDownload",
  DOWNLOAD_EVENT_DOC = "downloadEventDoc",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_TOKEN = "setToken",
  SET_LOGIN_USER = "setLoginUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_PERMISSION = "setPermission",
  SET_KEY_VALID_FLAG = "setKeyValidFlag",
  SET_RECENT_UPDATED_TYPE_DOCS = "setRecentUpdatedTypeDocs",
  SET_PUBLICATION_TYPE_ID = "setPublicationTypeId",
  SET_SECTOR = "setSector",
  SET_SECTOR_ID = "setSectorId",
  SET_ROLE = "setRole",
  SET_ROLE_ID = "setRoleId",
  SET_PUBLICATION_TYPE = "setPublicationType",
  SET_DOCUMENT_SEARCH_RESULT = "setDocumentSearchResult",
  SET_MINIMAL_DOCUMENTS = "setMinimalDocuments",
  SET_DOCUMENT_PUBLICATION_TYPES = "setDocumentPublicationTypes",
  SET_SEARCHING_FLAG = "setSearchingFlag",
  SET_USER_ACTIVITY_SEARCH_RESULT = "setUserActivitySearchResult",
  SET_USER_ACTIVITY_SOURCE = "setUserActivitySource",
  SET_COMPANY_SEARCH_RESULT = "setCompanySearchResult",
  SET_SECTOR_SEARCH_RESULT = "setSectorSearchResult",
  SET_PUBLICATION_TYPE_SEARCH_RESULT = "setPublicationTypeSearchResult",
  SET_ROLE_SEARCH_RESULT = "setRoleSearchResult",
  SET_EMAIL_TEMPLATE_SEARCH_RESULT = "setEmailTemplateSearchResult",
  SET_USER_SEARCH_RESULT = "setUserSearchResult",
  SET_USER_ID = "setUserId",
  SET_USER = "setUser",
  SET_COMPANY_ID = "setCompanyId",
  SET_COMPANY = "setCompany",
  SET_ROLE_PERMISSIONS = "setRolePermissions",
  SET_ALL_PERMISSIONS = "setAllPermissions",
  SET_USER_ROLES = "setUserRoles",
  SET_ALL_ROLES = "setAllRoles",
  SET_DOCUMENT = "setDocument",
  SET_DOCUMENT_ID = "setDocumentId",
  SET_DOCUMENT_SEARCH_WORD = "setDocumentSearchWord",
  SET_DOCUMENT_SEARCH_FLAG = "setDocumentSearchFlag",
  SET_EMAIL_TEMPLATE = "setEmailTemplate",
  SET_EMAIL_TEMPLATE_ID = "setEmailTemplateId",
  SET_ALL_USERS = "setAllUsers",
  SET_EMAIL_DRAFT_SEARCH_RESULT = "setEmailDraftSearchResult",
  SET_EMAIL_DRAFT_ID = "setEmailDraftId",
  SET_LIMITED_DOWNLOAD = "setLimitedDownload",
  SET_HOLIDAY_ID = "setHolidayId",
  SET_HOLIDAYS = "setHolidays",
  SET_HOLIDAY = "setHoliday",
  SET_NEXT_BUSINESS_DAY = "setNextBusinessDay",
  STORE_LOGIN_EMAIL = "storeLoginEmail",
  SET_EVENT_DOWNLOADS = "setEventDownloads",
  SET_EVENT_DOWNLOAD = "setEventDownload",
  SET_EVENT_DOWNLOAD_ID = "setEventDownloadId"
}

enum Endpoints {
  Token = "token",
  Users = "users",
  Documents = "documents",
  PublicationTypes = "publicationtypes",
  Sectors = "sectors",
  UserActivity = "useractivity",
  UserActivitySources = "useractivitysources",
  Companies = "companies",
  Roles = "roles",
  Email = "email",
  EmailDrafts = "emaildrafts",
  EmailTemplates = "emailtemplates",
  Permissions = "permissions",
  Queues = "queues",
  Holidays = "holidays",
  EventDownloads = "eventDownloads"
}

export { Actions, Mutations, Endpoints };
