import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";

import AuthModule from "@/store/modules/AuthModule";
import DocumentModule from "@/store/modules/DocumentModule";
import UserActivityModule from "@/store/modules/UserActivityModule";
import UserModule from "@/store/modules/UserModule";
import CompanyModule from "@/store/modules/CompanyModule";
import SectorModule from "@/store/modules/SectorModule";
import PublicationTypeModule from "@/store/modules/PublicationTypeModule";
import RoleModule from "@/store/modules/RoleModule";
import EmailModule from "@/store/modules/EmailModule";
import EmailTemplateModule from "@/store/modules/EmailTemplateModule";
import QueueModule from "@/store/modules/QueueModule";
import HolidayModule from "@/store/modules/HolidayModule";
import EventDownloadModule from "./modules/EventDownloadModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    DocumentModule,
    UserActivityModule,
    UserModule,
    CompanyModule,
    SectorModule,
    PublicationTypeModule,
    RoleModule,
    EmailTemplateModule,
    QueueModule,
    EmailModule,
    HolidayModule,
    EventDownloadModule
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
});

export default store;
