import {
  IBaseModule,
  IRoleSearchParam,
  IRoleResult,
  ISearchResult,
  IRole,
  IUserPermission,
  IPermissionGroup,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class RoleModule extends VuexModule implements IBaseModule {
  errors = {};
  searchResult = {} as ISearchResult<IRoleResult>;
  currentRole = {} as IRole;
  roleId = {};
  allPermissions = {} as Array<IPermissionGroup>;
  originalPermissions = {} as Array<string>;

  /**
   * Get role errors
   * @returns array
   */
  get getRoleErrors() {
    return this.errors;
  }

  /**
   * Get newly-created role ID
   * @returns string
   */
   get getRoleId() {
    return this.roleId;
  }

  /**
   * Get search result
   * @returns IRoleResult
   */
  get getRoleSearchResult(): ISearchResult<IRoleResult> {
    return this.searchResult;
  }

  /**
   * Get role
   * @returns IRole
   */
  get getRole(): IRole {
    return this.currentRole;
  }

  /**
   * Get all permissions
   * @returns Array<IPermissionGroup>
   */
  get getAllPermissions(): Array<IPermissionGroup> {
    return this.allPermissions;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_ROLE_ID](roleId: string) {
    this.roleId = roleId;
  }

  @Mutation
  [Mutations.SET_ROLE_SEARCH_RESULT](items: ISearchResult<IRoleResult>) {
    this.searchResult = items;
  }

  @Mutation
  [Mutations.SET_ROLE](role: IRole) {
    this.currentRole = role;
  }

  @Mutation
  [Mutations.SET_ALL_PERMISSIONS](permissions: Array<IPermissionGroup>) {
    this.allPermissions = permissions;
  }

  @Mutation
  [Mutations.SET_ROLE_PERMISSIONS](permissions: Array<IUserPermission>) {
    let role = this.currentRole;
    role.permissions = permissions.map(permission => permission.permissionId);

    this.currentRole = role;
    this.originalPermissions = permissions.map(permission => permission.permissionId);
  }

  @Action
  [Actions.LOAD_ROLE](roleId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Roles, roleId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the role.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_ROLE_PERMISSIONS](role: IRole) {
    if (role) {
      ApiService.setHeader();
      return ApiService.get(Endpoints.Roles, role.roleId + "/permissions")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLE_PERMISSIONS, data);
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error loading the role permissions.",
            ]);
            return;
          }
          if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    }
  }

  @Action
  [Actions.LOAD_ALL_PERMISSIONS]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Permissions, "grouped")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_PERMISSIONS, data.permissionGroups);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading permissions.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_ROLE](role: IRole) {
    ApiService.setHeader();
    const req = {
      name: role.name,
      description: role.description,
    } as any;
    
    return ApiService.post(Endpoints.Roles, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
        for (var i = 0; i < role.permissions.length; i++) {
          ApiService.post(Endpoints.Roles + "/" + this.roleId + "/permissions/" + role.permissions[i], {});
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the role.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_ROLE](role: IRole) {
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: role.name,
      },
      description: {
        op: "Replace",
        value: role.description,
      },
    } as any;

    for (var i = 0; i < this.originalPermissions.length; i++) {
      if (!role.permissions.includes(this.originalPermissions[i])) {
        ApiService.delete(Endpoints.Roles + "/" + role.roleId + "/permissions/" + this.originalPermissions[i]);
      }
    }

    for (var i = 0; i < role.permissions.length; i++) {
      if (!this.originalPermissions.includes(role.permissions[i])) {
        ApiService.post(Endpoints.Roles + "/" + role.roleId + "/permissions/" + role.permissions[i], {});
      }
    }

    return ApiService.patch(Endpoints.Roles + "/" + role.roleId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the role.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_ROLE](roleId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Roles, roleId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the role.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_ROLES](param: IRoleSearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Roles, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for roles.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
