import {
  IBaseModule,
  IHolidaySearchParam,
  ISearchResult,
  IHoliday,
  IBusinessDay,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class HolidayModule extends VuexModule implements IBaseModule {
  errors = {};
  holidays = {} as ISearchResult<IHoliday>;
  currentHoliday = {} as IHoliday;
  holidayId = {};
  nextBusinessDay = {} as IBusinessDay;

  /**
   * Get holiday errors
   * @returns array
   */
  get getHolidayErrors() {
    return this.errors;
  }

  /**
   * Get newly-created holiday ID
   * @returns string
   */
  get getHolidayId() {
    return this.holidayId;
  }

  /**
   * Get search result
   * @returns ISearchResult<IHoliday>
   */
  get getHolidays(): ISearchResult<IHoliday> {
    return this.holidays;
  }

  /**
   * Get holiday
   * @returns IHoliday
   */
  get getHoliday(): IHoliday {
    return this.currentHoliday;
  }

  get getNextBusinessDay(): IBusinessDay {
    return this.nextBusinessDay;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_HOLIDAY_ID](holidayId: string) {
    this.holidayId = holidayId;
  }

  @Mutation
  [Mutations.SET_HOLIDAYS](items: ISearchResult<IHoliday>) {
    this.holidays = items;
  }

  @Mutation
  [Mutations.SET_HOLIDAY](Holiday: IHoliday) {
    this.currentHoliday = Holiday;
  }

  @Mutation
  [Mutations.SET_NEXT_BUSINESS_DAY](item: IBusinessDay) {
    this.nextBusinessDay = item;
  }

  @Action
  [Actions.LOAD_HOLIDAY](holidayId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Holidays, holidayId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HOLIDAY, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the holiday.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_HOLIDAY](holiday: IHoliday) {
    ApiService.setHeader();
    const req = {
      name: holiday.name,
      date: holiday.date,
    } as any;
    return ApiService.post(Endpoints.Holidays, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HOLIDAY_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the holiday.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_HOLIDAY](holiday: IHoliday) {
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: holiday.name,
      },
      date: {
        op: "Replace",
        value: holiday.date,
      },
    } as any;
    return ApiService.patch(Endpoints.Holidays + "/" + holiday.holidayId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the holiday.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_HOLIDAY](holidayId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Holidays, holidayId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the holiday.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.NEXT_BUSINESS_DAY]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Holidays + "/nextbusinessday")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_NEXT_BUSINESS_DAY, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error getting the next business day.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_HOLIDAYS](param: IHolidaySearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }

    if (param.future) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "future=" + param.future;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Holidays, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HOLIDAYS, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for holidays.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
