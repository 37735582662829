
import { ref, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { IMessage, IUser } from "@/store/common/Types";
import modal from "@/components/Modal.vue";
import { debounce } from "ts-debounce";

export default {
  name: "NavBar",
  components: {
    modal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isUserAuthenticated);
    const userName = computed(() =>
      isLoggedIn ? store.getters.currentUser.firstName : ""
    );
    const canViewDocs = computed(() => store.getters.userCanViewDocuments);
    const canViewUsers = computed(() => store.getters.userCanViewUsers);
    const canSendEmail = computed(() => store.getters.userCanSendEmail);
    const canAccessCRM = computed(() => store.getters.userSyncCRM);
    const canViewUserActivity = computed(() => store.getters.userActivityView);
    const canViewSectors = computed(
      () =>
        store.getters.userCanCreateSectors || store.getters.userCanEditSectors
    );
    const canViewPublicationTypes = computed(
      () =>
        store.getters.userCanCreatePublicationTypes ||
        store.getters.userCanEditPublicationTypes
    );
    const canViewRoles = computed(
      () => store.getters.userCanCreateRoles || store.getters.userCanEditRoles
    );
    const canViewCompanies = computed(
      () =>
        store.getters.userCanCreateCompanies ||
        store.getters.userCanEditCompanies
    );
    const canViewEmailTemplates = computed(
      () =>
        store.getters.userCanCreateEmailTemplates ||
        store.getters.userCanEditEmailTemplates
    );
    const canViewHolidays = computed(
      () =>
        store.getters.userCanCreateHolidays || store.getters.userCanEditHolidays
    );
    const canViewEventDownloads = computed(
      () =>
        store.getters.userCanEditEventDownload
    );

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "login" }));
    };

    let showCrmModal = ref(false);

    const debouncedConfirmCrmSync = debounce(async () => {
      let message: IMessage = {
        queueName: "sugarcrmsync",
        message: { userId: (store.getters.currentUser as IUser).userId },
      };
      await store.dispatch(Actions.CREATE_MESSAGE, message);
      showCrmModal.value = false;
    });

    async function confirmCrmSync() {
      debouncedConfirmCrmSync();
    }

    return {
      isLoggedIn,
      userName,
      signOut,
      canViewDocs,
      canViewUsers,
      canSendEmail,
      canAccessCRM,
      canViewUserActivity,
      canViewSectors,
      canViewPublicationTypes,
      canViewRoles,
      canViewCompanies,
      canViewEmailTemplates,
      canViewHolidays,
      showCrmModal,
      confirmCrmSync,
      canViewEventDownloads
    };
  },
};
