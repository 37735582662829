import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Suspense as _Suspense, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { role: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_vfooter = _resolveComponent("vfooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createVNode(_component_NavBar)
    ]),
    _createVNode(_component_LoadingIndicator),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_Suspense, { timeout: "0" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ])
            ]),
            _: 2
          }, 1024))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_vfooter)
  ], 64))
}