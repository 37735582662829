import {
  IEmail,
  IBaseModule,
  IEmailDraftSearchParam,
  ISearchResult,
  IEmailDraft,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class EmailModule extends VuexModule implements IBaseModule {
  errors = {};
  emailDrafts = {} as ISearchResult<IEmailDraft>;
  emailDraftId = "" as string;

  /**
   * Get email errors
   * @returns array
   */
  get getEmailErrors() {
    return this.errors;
  }

  /**
   * Get email drafts
   * @returns SearchResult<IEmailDraft>
   */
  get getEmailDrafts(): ISearchResult<IEmailDraft> {
    return this.emailDrafts;
  }

  /**
   * Get email draft id
   * @returns string
   */
  get getEditingEmailDraftId(): string {
    return this.emailDraftId;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_EMAIL_DRAFT_SEARCH_RESULT](draft: ISearchResult<IEmailDraft>) {
    this.emailDrafts = draft;
  }

  @Mutation
  [Mutations.SET_EMAIL_DRAFT_ID](draftId: string) {
    this.emailDraftId = draftId;
  }

  @Action
  [Actions.SEND_EMAIL](email: IEmail) {
    this.context.commit(Mutations.SET_ERROR, {});
    ApiService.setHeader();
    return ApiService.post(Endpoints.Email, email as any)
      .then(({ data }) => {})
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error sending an email.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_EMAIL_DRAFT](param: IEmailDraftSearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.subject) {
      query += "subject=" + param.subject;
    }

    if (param.createdBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "createdBy=" + param.createdBy;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      let orderBy = "name";
      if (query.slice(-1) !== "?") {
        query += "&";
      }

      query += "orderBy=" + orderBy;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.EmailDrafts, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EMAIL_DRAFT_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for email drafts.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_EMAIL_DRAFT](draft: IEmailDraft) {
    ApiService.setHeader();
    const req = {
      subject: draft.subject,
      emailContent: draft.emailContent,
    } as any;
    return ApiService.post(Endpoints.EmailDrafts, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EMAIL_DRAFT_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the email draft.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  
  @Action
  [Actions.UPDATE_EMAIL_DRAFT](draft: IEmailDraft) {
    ApiService.setHeader();
    const req = {
      subject: {
        op: "Replace",
        value: draft.subject,
      },
      emailContent: {
        op: "Replace",
        value: draft.emailContent,
      },
    } as any;
    return ApiService.patch(Endpoints.EmailDrafts + "/" + draft.emailDraftId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the email draft.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_EMAIL_DRAFT](emailDraftId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.EmailDrafts, emailDraftId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the email draft.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
