import { getRoles } from "@/core/helpers/Permission";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {
  IUser,
  IUserAuthInfo,
  ILoginToken,
  IPermission,
} from "@/store/common/Types";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class AuthModule extends VuexModule implements IUserAuthInfo {
  errors = {};
  token = {} as ILoginToken;
  user = {} as IUser;
  permission = {} as IPermission;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): IUser {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getLoginErrors() {
    return this.errors;
  }

  /**
   * Get authentification token
   * @returns ILoginToken
   */
  get getLoginToken(): ILoginToken {
    return this.token;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getStoredEmail() {
    const storedEmail = JwtService.getEmailStr();
    return storedEmail;
  }

  /**
   * Get permission
   * @returns IPermission
   */
  get getPermission(): IPermission {
    return this.permission;
  }

  /**
   * Get userCanCreateDocuments permission
   * @returns boolean;
   */
  get userCanCreateDocuments(): boolean {
    return this.permission ? this.permission.DocumentCreate : false;
  }

  /**
   * Get userCanViewDocuments permission
   * @returns boolean
   */
  get userCanViewDocuments(): boolean {
    return this.permission ? this.permission.DocumentView : false;
  }

  /**
   * Get userCanEditDocuments permission
   * @returns boolean
   */
  get userCanEditDocuments(): boolean {
    return this.permission ? this.permission.DocumentEdit : false;
  }

  /**
   * Get userCanDeleteDocuments permission
   * @returns boolean
   */
  get userCanDeleteDocuments(): boolean {
    return this.permission ? this.permission.DocumentDelete : false;
  }

  /**
   * Get userCanShareDocuments permission
   * @returns boolean
   */
  get userCanShareDocuments(): boolean {
    return this.permission ? this.permission.DocumentShare : false;
  }

  /**
   * Get userCanViewUsers permission
   * @returns boolean
   */
  get userCanViewUsers(): boolean {
    return this.permission ? this.permission.ViewUsers : false;
  }

  /**
   * Get userCanSendEmail permission
   * @returns boolean
   */
  get userCanSendEmail(): boolean {
    return this.permission ? this.permission.SendEmail : false;
  }

  /**
   * Get userSyncCRM permission
   * @returns boolean
   */
  get userSyncCRM(): boolean {
    return this.permission ? this.permission.SyncSugerCRM : false;
  }

  /**
   * Get userActivityView permission
   * @returns boolean
   */
  get userActivityView(): boolean {
    return this.permission ? this.permission.UserActivityView : false;
  }

  /**
   * Get userCanCreateCompanies permission
   * @returns boolean
   */
  get userCanCreateCompanies(): boolean {
    return this.permission ? this.permission.CompanyCreate : false;
  }

  /**
   * Get userCanEditCompanies permission
   * @returns boolean
   */
  get userCanEditCompanies(): boolean {
    return this.permission ? this.permission.CompanyEdit : false;
  }

  /**
   * Get userCanDeleteCompanies permission
   * @returns boolean
   */
  get userCanDeleteCompanies(): boolean {
    return this.permission ? this.permission.CompanyDelete : false;
  }

  /**
   * Get userCanCreateSectors permission
   * @returns boolean
   */
  get userCanCreateSectors(): boolean {
    return this.permission ? this.permission.SectorCreate : false;
  }

  /**
   * Get userCanEditSectors permission
   * @returns boolean
   */
  get userCanEditSectors(): boolean {
    return this.permission ? this.permission.SectorEdit : false;
  }

  /**
   * Get userCanDeleteSectors permission
   * @returns boolean
   */
  get userCanDeleteSectors(): boolean {
    return this.permission ? this.permission.SectorDeleted : false;
  }

  /**
   * Get userCanCreatePublicationTypes permission
   * @returns boolean
   */
  get userCanCreatePublicationTypes(): boolean {
    return this.permission ? this.permission.PubliationTypeCreate : false;
  }

  /**
   * Get userCanEditPublicationTypes permission
   * @returns boolean
   */
  get userCanEditPublicationTypes(): boolean {
    return this.permission ? this.permission.PublicationTypeEdit : false;
  }

  /**
   * Get userCanDeletePublicationTypes permission
   * @returns boolean
   */
  get userCanDeletePublicationTypes(): boolean {
    return this.permission ? this.permission.PublicationTypeDelete : false;
  }

  /**
   * Get userCanCreateRoles permission
   * @returns boolean
   */
  get userCanCreateRoles(): boolean {
    return this.permission ? this.permission.RoleCreate : false;
  }

  /**
   * Get userCanEditRoles permission
   * @returns boolean
   */
  get userCanEditRoles(): boolean {
    return this.permission ? this.permission.RoleEdit : false;
  }

  /**
   * Get userCanDeleteRoles permission
   * @returns boolean
   */
  get userCanDeleteRoles(): boolean {
    return this.permission ? this.permission.RoleDelete : false;
  }

  /**
   * Get userCanCreateEmailTemplates permission
   * @returns boolean
   */
  get userCanCreateEmailTemplates(): boolean {
    return this.permission ? this.permission.EmailTemplateCreate : false;
  }

  /**
   * Get userCanEditEmailTemplates permission
   * @returns boolean
   */
  get userCanEditEmailTemplates(): boolean {
    return this.permission ? this.permission.EmailTemplateEdit : false;
  }

  /**
   * Get userCanDeleteEmailTemplates permission
   * @returns boolean
   */
  get userCanDeleteEmailTemplates(): boolean {
    return this.permission ? this.permission.EmailTemplateDelete : false;
  }

  /**
   * Get userCanCreateUsers permission
   * @returns boolean
   */
  get userCanCreateUsers(): boolean {
    return this.permission ? this.permission.UserCreate : false;
  }

  /**
   * Get userCanEditUsers permission
   * @returns boolean
   */
  get userCanEditUsers(): boolean {
    return this.permission ? this.permission.UserEdit : false;
  }

  /**
   * Get userCanDeleteUsers permission
   * @returns boolean
   */
  get userCanDeleteUsers(): boolean {
    return this.permission ? this.permission.UserDelete : false;
  }

  /**
   * Get userCanViewMetadata permission
   * @returns boolean
   */
  get userCanViewMetadata(): boolean {
    return this.permission ? this.permission.MetadataView : false;
  }

  /**
   * Get userCanCreateHolidays permission
   * @returns boolean
   */
  get userCanCreateHolidays(): boolean {
    return this.permission ? this.permission.HolidayCreate : false;
  }

  /**
   * Get userCanEditHolidays permission
   * @returns boolean
   */
  get userCanEditHolidays(): boolean {
    return this.permission ? this.permission.HolidayEdit : false;
  }

    /**
   * Get userCanEditHolidays permission
   * @returns boolean
   */
    get userCanEditEventDownload(): boolean {
      return this.permission ? this.permission.EventDownloadEdit : false;
    }

  @Mutation
  [Mutations.SET_ERROR](error) {
    if (error !== undefined) {
      this.errors = { ...error };
    }
  }

  @Mutation
  [Mutations.SET_AUTH](payload) {
    this.isAuthenticated = true;
    this.errors = {};
    JwtService.saveTokens(payload?.token, payload?.refreshToken);
  }

  @Mutation
  [Mutations.SET_TOKEN](token) {
    const tokenObj = JwtService.parseJWT(token);
    this.token = tokenObj as unknown as ILoginToken;
    if (this.token && this.token.scope) {
      JwtService.saveRoleStr(this.token.scope.toString());
    }
  }

  @Mutation
  [Mutations.SET_LOGIN_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as IUser;
    this.permission = {} as IPermission;
    this.errors = [];
    JwtService.destroyTokens();
  }

  @Mutation
  [Mutations.SET_PERMISSION](permission: IPermission) {
    this.permission = permission;
  }

  @Mutation
  [Mutations.STORE_LOGIN_EMAIL](email: string) {
    JwtService.saveLoginEmail(email);
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post(Endpoints.Token, credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_TOKEN, data.token);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "Login Failed: There was an error verifying your credentials",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, [
            "Login Failed: " + response.data.errors[0].errorMessage,
          ]);
          return;
        }
        this.context.commit(Mutations.SET_ERROR, [
          "Login Failed: Email address and/or password is incorrect",
        ]);
      });
  }

  @Action
  [Actions.LOAD_LOGIN_USER]() {
    if (!this.token || !this.token.userId) {
      return;
    }
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users + "/" + this.token.userId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LOGIN_USER, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error getting the user.",
          ]);
          return;
        }
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOAD_PERMISSION]() {
    let permission = {} as IPermission;
    permission.Permissions = getRoles();
    permission.ViewUsers = permission.Permissions.includes("UserView");
    permission.SendEmail = permission.Permissions.includes("CanSendEmail");
    permission.SyncSugerCRM = permission.Permissions.includes("SyncSugarCrm");
    permission.DocumentCreate =
      permission.Permissions.includes("DocumentCreate");
    permission.DocumentView = permission.Permissions.includes("DocumentView");
    permission.DocumentEdit = permission.Permissions.includes("DocumentEdit");
    permission.DocumentDelete =
      permission.Permissions.includes("DocumentDelete");
    permission.DocumentShare = permission.Permissions.includes("DocumentShare");
    permission.UserActivityView =
      permission.Permissions.includes("UserActivityView");
    permission.CompanyCreate = permission.Permissions.includes("CompanyCreate");
    permission.CompanyEdit = permission.Permissions.includes("CompanyEdit");
    permission.CompanyDelete = permission.Permissions.includes("CompanyDelete");
    permission.SectorCreate = permission.Permissions.includes("SectorCreate");
    permission.SectorEdit = permission.Permissions.includes("SectorEdit");
    permission.SectorDeleted = permission.Permissions.includes("SectorDelete");
    permission.PubliationTypeCreate = permission.Permissions.includes(
      "PublicationTypeCreate"
    );
    permission.PublicationTypeEdit = permission.Permissions.includes(
      "PublicationTypeEdit"
    );
    permission.PublicationTypeDelete = permission.Permissions.includes(
      "PublicationTypeDelete"
    );
    permission.RoleCreate = permission.Permissions.includes("RoleCreate");
    permission.RoleEdit = permission.Permissions.includes("RoleEdit");
    permission.RoleDelete = permission.Permissions.includes("RoleDelete");
    permission.EmailTemplateCreate = permission.Permissions.includes(
      "EmailTemplateCreate"
    );
    permission.EmailTemplateEdit =
      permission.Permissions.includes("EmailTemplateEdit");
    permission.EmailTemplateDelete = permission.Permissions.includes(
      "EmailTemplateDelete"
    );
    permission.UserCreate = permission.Permissions.includes("UserCreate");
    permission.UserEdit = permission.Permissions.includes("UserEdit");
    permission.UserDelete = permission.Permissions.includes("UserDelete");
    permission.MetadataView = permission.Permissions.includes(
      "DocumentMetadataView"
    );
    permission.HolidayCreate = permission.Permissions.includes("HolidayCreate");
    permission.HolidayEdit = permission.Permissions.includes("HolidayEdit");
    permission.EventDownloadEdit = permission.Permissions.includes("EventDownloadEdit");

    this.context.commit(Mutations.SET_PERMISSION, permission);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post(
      Endpoints.Users + "/forgot?email=" + payload.email,
      payload
    )
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    const token = payload?.api_token;
    const refreshToken = payload?.refresh_token;

    if (!token) {
      this.context.commit(Mutations.PURGE_AUTH);
      return;
    }

    this.context.commit(Mutations.SET_TOKEN, token);
    if (!this.token) {
      this.context.commit(Mutations.PURGE_AUTH);
      return;
    }

    if (
      Date.now() >
      (this.token.exp as unknown as number) * 1000 //Expired.
    ) {
      this.context.commit(Mutations.PURGE_AUTH);
      return;
    }

    const refreshTokenMinutes = 15; //set 15 mins to refresh before expired.
    if (
      Date.now() + refreshTokenMinutes * 60000 >
      (this.token.exp as unknown as number) * 1000
    ) {
      const req = {
        refreshToken: refreshToken,
      } as any;
      return ApiService.post(Endpoints.Token + "/refresh", req)
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.SET_TOKEN, data.data.token);
        })
        .catch(({ response }) => {
          //We don't do anthing for natural expiration.
        });
    }
  }

  @Action
  [Actions.SAVE_TERMS]() {
    ApiService.setHeader();
    const req = {
      termsAcceptedOn: {
        op: "replace",
        value: new Date().toISOString(),
      },
    } as any;
    return ApiService.patch(Endpoints.Users + "/" + this.token.userId, req)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "Could not update terms and conditions acceptance. Please try again.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
