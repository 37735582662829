
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Modal",
  components: { Form, Field, ErrorMessage },
  props: ["size", "form", "delete", "crmsync", "restore", "okonly"],
  setup(props, { emit }) {
    function onSubmit(event) {
      //Nexttick doesn't work so have to hardcode a pause after ui update.
      //setTimeout(function () {
      emit("confirm", event);
      // }, 500);
    }
    return {
      props,
      onSubmit,
    };
  },
};
