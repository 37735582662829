import {
  IBaseModule,
  ISectorSearchParam,
  ISectorResult,
  ISearchResult,
  ISector,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class SectorModule extends VuexModule implements IBaseModule {
  errors = {};
  searchResult = {} as ISearchResult<ISectorResult>;
  currentSector = {} as ISector;
  sectorId = {};

  /**
   * Get sector errors
   * @returns array
   */
  get getSectorErrors() {
    return this.errors;
  }

  /**
   * Get newly-created sector ID
   * @returns string
   */
   get getSectorId() {
    return this.sectorId;
  }

  /**
   * Get search result
   * @returns ISectorResult
   */
  get getSectorSearchResult(): ISearchResult<ISectorResult> {
    return this.searchResult;
  }

  /**
   * Get sector
   * @returns ISector
   */
  get getSector(): ISector {
    return this.currentSector;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_SECTOR_ID](sectorId: string) {
    this.sectorId = sectorId;
  }

  @Mutation
  [Mutations.SET_SECTOR_SEARCH_RESULT](items: ISearchResult<ISectorResult>) {
    this.searchResult = items;
  }

  @Mutation
  [Mutations.SET_SECTOR](sector: ISector) {
    this.currentSector = sector;
  }

  //todo: Move SET_SECTORS from DocumentModule

  @Action
  [Actions.LOAD_SECTOR](sectorId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Sectors, sectorId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SECTOR, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the sector.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_SECTOR](sector: ISector) {
    ApiService.setHeader();
    const req = {
      name: sector.name,
      latestDocumentsSortOrder: sector.latestDocumentsSortOrder,
    } as any;
    return ApiService.post(Endpoints.Sectors, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SECTOR_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the sector.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_SECTOR](sector: ISector) {
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: sector.name,
      },
    } as any;
    if (!sector.latestDocumentsSortOrder) {
      req.latestDocumentsSortOrder = {
        op: "Remove"
      }
    }
    else {
      req.latestDocumentsSortOrder = {
        op: "Replace",
        value: sector.latestDocumentsSortOrder
      }
    }
    return ApiService.patch(Endpoints.Sectors + "/" + sector.sectorId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the sector.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_SECTOR](sectorId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Sectors, sectorId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the sector.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_SECTORS](param: ISectorSearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Sectors, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SECTOR_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for sectors.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
