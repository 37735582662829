import {
  IBaseModule,
  ICompanySearchParam,
  ICompanyResult,
  ISearchResult,
  ICompany,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class CompanyModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  searchResult = {} as ISearchResult<ICompanyResult>;
  currentCompany = {} as ICompany;
  companyId = {};

  /**
   * Get company errors
   * @returns array
   */
  get getCompanyErrors() {
    return this.errors;
  }

  /**
   * Get newly-created company ID
   * @returns string
   */
   get getCompanyId() {
    return this.companyId;
  }

  /**
   * Get search result
   * @returns ICompanyResult
   */
  get getCompanySearchResult(): ISearchResult<ICompanyResult> {
    return this.searchResult;
  }

  /**
   * Get company
   * @returns ICompany
   */
  get getCompany(): ICompany {
    return this.currentCompany;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_COMPANY_ID](companyId: string) {
    this.companyId = companyId;
  }

  @Mutation
  [Mutations.SET_COMPANY_SEARCH_RESULT](items: ISearchResult<ICompanyResult>) {
    this.searchResult = items;
  }

  @Mutation
  [Mutations.SET_COMPANY](company: ICompany) {
    this.currentCompany = company;
  }

  @Action
  [Actions.LOAD_COMPANY](companyId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Companies, companyId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COMPANY, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the company.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_COMPANY](company: ICompany) {
    ApiService.setHeader();
    const req = {
      name: company.name,
      tickerSymbol: company.tickerSymbol,
      website: company.website,
      isListed: company.isListed,
    } as any;
    return ApiService.post(Endpoints.Companies, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COMPANY_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the company.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_COMPANY](company: ICompany) {
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: company.name,
      },
      tickerSymbol: {
        op: "Replace",
        value: company.tickerSymbol,
      },
      website: {
        op: "Replace",
        value: company.website,
      },
      isListed: {
        op: "Replace",
        value: company.isListed,
      },
    } as any;
    return ApiService.patch(Endpoints.Companies + "/" + company.companyId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the company.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_COMPANY](companyId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Companies, companyId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the company.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_COMPANIES](param: ICompanySearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }

    if (param.tickerSymbol) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "tickerSymbol=" + param.tickerSymbol;
    }

    if (param.isListed) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isListed=" + param.isListed;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Companies, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COMPANY_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading companies.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
