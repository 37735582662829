const ID_TOKEN_KEY = "petersco_DqsnfIXuXvXZoMmkIOph_token" as string;
const ID_REFRESH_TOKEN_KEY = "petersco_mriACiGwOt_refresh_token" as string;
const ID_ROLE_KEY = "petersco_d1nYf9Pj9ANdoVlAhfsg_STR" as string;
const ID_EMAIL_KEY = "petersco_d1nYf9Pj9ANdoVlAhfsg_EMAIL" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save tokens into localStorage
 * @param token: string
 * @param refreshToken: string
 */
export const saveTokens = (token: string, refreshToken: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, refreshToken);
};

/**
 * @description remove tokens form localStorage
 */
export const destroyTokens = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(ID_ROLE_KEY);
};

/**
 * @description decode JWT token to token object
 * @param token: string
 */
export const parseJWT = (token): JSON => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/**
 * @description get refresh token form localStorage
 */
export const getRefreshToken = (): string | null => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

/**
 * @description get role form localStorage
 */
export const getRoleStr = (): string | null => {
  return window.localStorage.getItem(ID_ROLE_KEY);
};

/**
 * @description save role into localStorage
 * @param role: string
 */
export const saveRoleStr = (role: string): void => {
  window.localStorage.setItem(ID_ROLE_KEY, role);
};

/**
 * @description save login email into localStorage
 * @param role: string
 */
export const saveLoginEmail = (email: string): void => {
  window.localStorage.setItem(ID_EMAIL_KEY, email);
};

/**
 * @description get stored email form localStorage
 */
export const getEmailStr = (): string | null => {
  return window.localStorage.getItem(ID_EMAIL_KEY);
};

export default {
  getToken,
  getRefreshToken,
  getRoleStr,
  saveTokens,
  saveRoleStr,
  destroyTokens,
  parseJWT,
  saveLoginEmail,
  getEmailStr,
};
