import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-header d-flex justify-content-center" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }
const _hoisted_6 = { class: "w-100 d-flex justify-content-between" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'modal-container',
          $setup.props.size === 'm' ? 'modal-medium' : '',
          $setup.props.size === 'l' ? 'modal-large overflow-auto' : '',
        ])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _createVNode(_component_Form, {
          onSubmit: _cache[3] || (_cache[3] = ($event: any) => ($setup.onSubmit(null)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "body")
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _createElementVNode("div", _hoisted_6, [
                  ($setup.props.okonly)
                    ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                        _createElementVNode("button", {
                          class: "btn btn-sm btn-primary",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel'))),
                          id: "modal-cancel"
                        }, " OK ")
                      ])
                    : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
                        _createElementVNode("button", {
                          class: "btn btn-sm btn-light",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel'))),
                          id: "modal-cancel"
                        }, " Cancel "),
                        (!$setup.props.form)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: _normalizeClass([
                      'btn',
                      'btn-sm',
                      'btn-modal',
                      $setup.props.delete ? 'btn-danger' : 'btn-primary',
                    ]),
                              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.onSubmit($event)))
                            }, [
                              (!$setup.props.delete && !$setup.props.crmsync && !$setup.props.restore)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "OK"))
                                : ($setup.props.crmsync)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "SYNC"))
                                  : ($setup.props.restore)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "RESTORE"))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_10, "DELETE"))
                            ], 2))
                          : (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              type: "submit",
                              class: _normalizeClass([
                      'btn',
                      'btn-sm',
                      'btn-modal',
                      $setup.props.delete ? 'btn-danger' : 'btn-primary',
                    ])
                            }, " OK ", 2))
                      ])
                ])
              ])
            ])
          ]),
          _: 3
        })
      ], 2)
    ])
  ]))
}