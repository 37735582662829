import {
  IBaseModule,
  IPublicationTypeSearchParam,
  ISearchResult,
  IPublicationType,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class PublicationTypeModule extends VuexModule implements IBaseModule {
  errors = {};
  searchResult = {} as ISearchResult<IPublicationType>;
  currentPublicationType = {} as IPublicationType;
  publicationTypeId = {};

  /**
   * Get publication type errors
   * @returns array
   */
  get getPublicationTypeErrors() {
    return this.errors;
  }

  /**
   * Get newly-created publication type ID
   * @returns string
   */
  get getPublicationTypeId() {
    return this.publicationTypeId;
  }

  /**
   * Get search result
   * @returns IPublicationType
   */
  get getPublicationTypeSearchResult(): ISearchResult<IPublicationType> {
    return this.searchResult;
  }

  /**
   * Get publication type
   * @returns IPublicationType
   */
  get getPublicationType(): IPublicationType {
    return this.currentPublicationType;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PUBLICATION_TYPE_ID](publicationTypeId: string) {
    this.publicationTypeId = publicationTypeId;
  }

  @Mutation
  [Mutations.SET_PUBLICATION_TYPE_SEARCH_RESULT](items: ISearchResult<IPublicationType>) {
    this.searchResult = items;
  }

  @Mutation
  [Mutations.SET_PUBLICATION_TYPE](publicationType: IPublicationType) {
    this.currentPublicationType = publicationType;
  }

  @Action
  [Actions.LOAD_PUBLICATION_TYPE](publicationTypeId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.PublicationTypes, publicationTypeId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PUBLICATION_TYPE, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the publication type.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_PUBLICATION_TYPE](publicationType: IPublicationType) {
    ApiService.setHeader();
    const req = {
      name: publicationType.name,
      isHidden: publicationType.isHidden,
      hideDocuments: publicationType.hideDocuments,
    } as any;
    return ApiService.post(Endpoints.PublicationTypes, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PUBLICATION_TYPE_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the publication type.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_PUBLICATION_TYPE](publicationType: IPublicationType) {
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: publicationType.name,
      },
      isHidden: {
        op: "Replace",
        value: publicationType.isHidden,
      },
      hideDocuments: {
        op: "Replace",
        value: publicationType.hideDocuments,
      },
    } as any;
    return ApiService.patch(Endpoints.PublicationTypes + "/" + publicationType.publicationTypeId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the publication type.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_PUBLICATION_TYPE](publicationTypeId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.PublicationTypes, publicationTypeId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the publication type.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_PUBLICATION_TYPES](param: IPublicationTypeSearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }

    if (param.includeHidden) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "includeHidden=" + param.includeHidden;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.PublicationTypes, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PUBLICATION_TYPE_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for publication types.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
