
import NavBar from "@/components/NavBar.vue";
import vfooter from "@/components/Footer.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  name: "app",
  components: {
    NavBar,
    vfooter,
    LoadingIndicator,
  },
};
