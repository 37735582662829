import {
  IBaseModule,
  IUserActivitySearchParam,
  IUserActivityResult,
  ISearchResult,
  IUser,
  IUserActivitySource,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserActivityModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  searchResult = {} as ISearchResult<IUserActivityResult>;
  allUsers = {} as Array<IUser>;
  activitySources = {} as ISearchResult<IUserActivitySource>;

  /**
   * Get user activity errors
   * @returns array
   */
  get getUserActivityErrors() {
    return this.errors;
  }

  /**
   * Get search result
   * @returns IUserActivityResult
   */
  get getUserActivitySearchResult(): ISearchResult<IUserActivityResult> {
    return this.searchResult;
  }

  /**
   * Get users
   * @returns Array<IUser>
   */
  get getUsers(): Array<IUser> {
    return this.allUsers;
  }

  /**
   * Get search result
   * @returns IUserActivityResult
   */
  get getUserActivitySources(): ISearchResult<IUserActivitySource> {
    return this.activitySources;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_USER_ACTIVITY_SEARCH_RESULT](
    items: ISearchResult<IUserActivityResult>
  ) {
    this.searchResult = items;
  }

  @Mutation
  [Mutations.SET_ALL_USERS](users: Array<IUser>) {
    this.allUsers = users;
  }

  @Mutation
  [Mutations.SET_USER_ACTIVITY_SOURCE](
    items: ISearchResult<IUserActivitySource>
  ) {
    this.activitySources = items;
  }

  @Action
  [Actions.LOAD_USERS]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, "?page=0")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_USERS, data.items);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading users.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_USER_ACTIVITY](param: IUserActivitySearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.userId) {
      query += "userId=" + param.userId;
    }

    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query +=
        "startDate=" +
        new Date(
          Date.UTC(
            param.startDate.getFullYear(),
            param.startDate.getMonth(),
            param.startDate.getDate(),
            0,
            0,
            0,
            0
          )
        ).toISOString();
    }

    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query +=
        "endDate=" +
        new Date(
          Date.UTC(
            param.endDate.getFullYear(),
            param.endDate.getMonth(),
            param.endDate.getDate(),
            23,
            59,
            59,
            999
          )
        ).toISOString();
    }

    if (param.activityType) {
      for (let i = 0; i < param.activityType.length; i++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "activityType=" + param.activityType[i];
      }
    }

    if (param.url) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "url=" + param.url;
    }

    if (param.searchCriteria) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "searchCriteria=" + param.searchCriteria;
    }

    if (param.resourceType) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resourceType=" + param.resourceType;
    }

    if (param.resourceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resourceId=" + param.resourceId;
    }

    if (param.userActivitySourceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "userActivitySourceId=" + param.userActivitySourceId;
    }

    if (param.sectorId) {
      for (let i = 0; i < param.sectorId.length; i++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "sectorId=" + param.sectorId[i];
      }
    }

    if (param.publicationTypeId) {
      for (let i = 0; i < param.publicationTypeId.length; i++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "publicationTypeId=" + param.publicationTypeId[i];
      }
    }

    if (param.tickerSymbol) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "tickerSymbol=" + param.tickerSymbol;
    }

    if (param.documentId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "documentId=" + param.documentId;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      let orderBy = "activitydate";
      if (query.slice(-1) !== "?") {
        query += "&";
      }

      query += "orderBy=" + orderBy;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.UserActivity, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ACTIVITY_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for user activities.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DOWNLOAD_USER_ACTIVITY](param: IUserActivitySearchParam) {
    let query = "csv?";
    if (param.userId) {
      query += "userId=" + param.userId;
    }

    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query +=
        "startDate=" +
        new Date(
          Date.UTC(
            param.startDate.getFullYear(),
            param.startDate.getMonth(),
            param.startDate.getDate(),
            0,
            0,
            0,
            0
          )
        ).toISOString();
    }

    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query +=
        "endDate=" +
        new Date(
          Date.UTC(
            param.endDate.getFullYear(),
            param.endDate.getMonth(),
            param.endDate.getDate(),
            23,
            59,
            59,
            999
          )
        ).toISOString();
    }

    if (param.activityType) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "activityType=" + param.activityType;
    }

    if (param.url) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "url=" + param.url;
    }

    if (param.searchCriteria) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "searchCriteria=" + param.searchCriteria;
    }

    if (param.resourceType) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resourceType=" + param.resourceType;
    }

    if (param.resourceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resourceId=" + param.resourceId;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.UserActivity, query)
      .then(({ data }) => {
        var atData = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
        var blob = new Blob([atData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "useractivity" + new Date().toISOString() + ".csv"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error downloading user activity.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_USER_ACTIVITIY_SOURCES]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.UserActivitySources)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ACTIVITY_SOURCE, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for user activity sources.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
