import {
  IBaseModule,
  IEmailTemplateSearchParam,
  IEmailTemplateResult,
  ISearchResult,
  IEmailTemplate,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class EmailTemplateModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  searchResult = {} as ISearchResult<IEmailTemplateResult>;
  currentEmailTemplate = {} as IEmailTemplate;
  emailTemplateId = {};

  /**
   * Get email template errors
   * @returns array
   */
  get getEmailTemplateErrors() {
    return this.errors;
  }

  /**
   * Get newly-created email template ID
   * @returns string
   */
  get getEmailTemplateId() {
    return this.emailTemplateId;
  }

  /**
   * Get search result
   * @returns IEmailTemplateResult
   */
  get getEmailTemplateSearchResult(): ISearchResult<IEmailTemplateResult> {
    return this.searchResult;
  }

  /**
   * Get email template
   * @returns IEmailTemplate
   */
  get getEmailTemplate(): IEmailTemplate {
    return this.currentEmailTemplate;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_EMAIL_TEMPLATE_ID](emailTemplateId: string) {
    this.emailTemplateId = emailTemplateId;
  }

  @Mutation
  [Mutations.SET_EMAIL_TEMPLATE_SEARCH_RESULT](items: ISearchResult<IEmailTemplateResult> ) {
    this.searchResult = items;
  }

  @Mutation
  [Mutations.SET_EMAIL_TEMPLATE](emailTemplate: IEmailTemplate) {
    this.currentEmailTemplate = emailTemplate;
  }

  @Action
  [Actions.LOAD_EMAIL_TEMPLATE](emailTemplateId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.EmailTemplates, emailTemplateId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EMAIL_TEMPLATE, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the email template.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_EMAIL_TEMPLATE](emailTemplate: IEmailTemplate) {
    ApiService.setHeader();
    const req = {
      name: emailTemplate.name,
      templateContent: emailTemplate.templateContent,
    } as any;
    return ApiService.post(Endpoints.EmailTemplates, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EMAIL_TEMPLATE_ID, data);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the email template.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_EMAIL_TEMPLATE](emailTemplate: IEmailTemplate) {
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: emailTemplate.name,
      },
      templateContent: {
        op: "Replace",
        value: emailTemplate.templateContent,
      },
    } as any;
    return ApiService.patch(Endpoints.EmailTemplates + "/" + emailTemplate.emailTemplateId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the email template.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_EMAIL_TEMPLATE](emailTemplateId: string) {
    ApiService.setHeader();
    return ApiService.delete(Endpoints.EmailTemplates, emailTemplateId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the email template.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_EMAIL_TEMPLATES](param: IEmailTemplateSearchParam) {
    if (!param) {
      return;
    }

    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.EmailTemplates, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EMAIL_TEMPLATE_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading email templates.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
